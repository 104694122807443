<template>
  <div>
    <div class="container-fluid" id="nav-container">
      <nav-bar></nav-bar>
    </div>
    <div class="container-fluid">
      <div class="row" id="first">
        <div class="col-12">
          <h1>{{ first.TITLE }}</h1>
          <p>
            {{ first.SUBTITLE }} <br />
            <span> {{ first.SUBSUB }}</span>
          </p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 col-12" v-for="(img, key) in images" :key="key">
          <img class="img-fluid" :src="require('@/assets/Images/' + img)" alt="img" />
        </div>
      </div>
      <div class="row" id="checks">
        <div class="col-12">
          <div class="row" data-aos="fade-right">
            <div class="col-12 title">
              <h1>{{ check.TITLE }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="check" v-for="(text, key) in check.CHECKS" :key="key" data-aos="fade-right">
                <img src="@/assets/Images/Practice/Icon awesome-check-circle.png" alt="check" />
                <p>
                  {{ text }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="semaine">
        <div class="col-12">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <img v-if="semaineImg == null" class="img-fluid" src="@/assets/Images/Cycles/Enmascarar grupo 193.png" alt="img" />
              <img v-else class="img-fluid" :src="semaineImg" alt="img" />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-html="semaine"></div>
          </div>
          <div class="row">
            <div class="col-12">
              <h3>
                {{ locale[lang].CYCLES.PROGRAMME }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="apres">
        <div class="col-12">
          <div class="row" data-aos="fade-right">
            <div class="col-12 title">
              <h1>
                {{ locale[lang].CYCLES.APRES.TITLE }}
              </h1>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-html="apres.COL1"></div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" v-html="apres.COL2"></div>
          </div>
          <div class="row" data-aos="fade-right">
            <div class="col-12">
              <h4 v-if="!apres.SUB">
                {{ locale[lang].CYCLES.APRES.END }}
              </h4>
              <h4 v-else v-html="apres.SUB">
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="programme">
        <div class="col-12">
          <div class="row" id="paypal">
            <div class="col-12" v-if="!infoShop">
              <div class="row" id="card">
                <div class="col-12">
                  <div class="text-card">
                    <h1>
                      Nous vous proposons ce nouveau Programme en ligne
                    </h1>
                    <p>ccédez directement au programme après votre commande.</p>
                    <p>
                      Profitez de l'offre promotionnelle jusqu'au dimanche 22
                      novembre 2020 :
                    </p>
                    <div class="price">
                      <h2>9,90€</h2>
                      <p>au lieu de</p>
                      <h2 class="offer">15,90€</h2>
                    </div>
                  </div>
                  <div class="row buttons">
                    <button class="btn btn-pp">
                      <img class="img-fluid" src="@/assets/Images/Idees/PayPal-Logo@2x.png" alt="paypal" />
                    </button>
                    <button class="btn btn-tc">
                      <i class="fas fa-credit-card"></i>Tarjeta de crédito
                    </button>
                  </div>
                  <div class="dev">
                    <p>
                      Desarrollo por
                      <img src="@/assets/Images/Idees/PayPal-Logo.png" alt="paypal" />
                    </p>
                  </div>
                  <div class="text-card end">
                    <p>
                      Cliquez sur le bouton noir pour payer par carte bancaire (si
                      vous n'avez pas de compte paypal)
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ProductShop :programme="true" :change="locale[lang].CHANGE_P" :info="infoShop" v-else />
          </div>
          <div class="row" id="satisfaction">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="garantie">
              <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].GARANTIE_IMG)" alt="garantie" />
              <h2> {{ locale[lang].CYCLES.GARANTIE.TITLE }} </h2>
              <p>
                {{ locale[lang].CYCLES.GARANTIE.SUBTITLE }}
              </p>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12" id="paiement">
              <img class="img-fluid" :src="require('@/assets/Images/Practice/' + locale[lang].PAY_IMG)" alt="paiement" />
              <h2> {{ locale[lang].CYCLES.PAIEMENT.TITLE }} </h2>
              <p>
                {{ locale[lang].CYCLES.PAIEMENT.SUBTITLE }}
              </p>
            </div>
            <div class="col-12" id="page">
              <h3>
                {{ locale[lang].CYCLES.CONTACT.TEXT_1 }}
                <br />
                {{ locale[lang].CYCLES.CONTACT.TEXT_2 }}
                <a href="javascript:;" @click="$router.push({ name: 'Contacto' })">{{ locale[lang].CYCLES.CONTACT.LINK }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row" id="exercices">
        <div class="col-12">
          <div class="row justify-content-center">
            <div class="col-12 title" data-aos="fade-right">
              <h1>{{ exercices.TITLE }}</h1>
            </div>
            <p>
              {{ exercices.SUBTITLE }}
            </p>
          </div>
          <div class="row justify-content-center" v-if="!mobile">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(e, key) in exercices.EXERCICESL" :key="key">
                <ExerciceCard :info="e" :id="'left-' + key" />
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="row" v-for="(e, key) in exercices.EXERCICESR" :key="key">
                <ExerciceCard :info="e" :id="'right-' + key" />
              </div>
            </div>
          </div>
          <div class="row justify-content-center" v-else>
            <ExerciceCardsM :infoCards="infoCards" />
          </div>
        </div>
        <br />
      </div>
    </div>
    <div class="container-fluid" id="footer-container" >
      <Footer />
    </div>
  </div>
</template>

<script>
import ExerciceCard from "@/components/ExerciceCard.vue";
import ExerciceCardsM from "@/components/ExerciceCardsM.vue";
import ProductShop from "@/components/ProductShop.vue";
import defaultMixin from "@/mixins/defaultMixin";
import NavBar from "@/components/essential/NavBar.vue";
import Footer from "@/components/essential/Footer.vue";
export default {
  mixins: [defaultMixin],
  components: {
    ExerciceCard,
    ExerciceCardsM,
    ProductShop,
    NavBar,
    Footer,
  },
  props: {
    first: Object,
    images: Array,
    check: Object,
    semaine: String,
    semaineImg: String,
    apres: Object,
    exercices: Object,
    infoShop: Object,
  },
  computed: {
    infoCards() {
      return this.exercices.EXERCICESL.concat(this.exercices.EXERCICESR);
    },
    footer() {
      return this.$store.state.footer;
    },
  },
};
</script>

<style scoped>
#first {
  background-color: #f7f7f7!important;
  background-image: url("../assets/Images/Training/Enmascarar grupo 174.png");
  background-size: auto;
  background-repeat: no-repeat;
  text-align: left;
  background-position: 0 150%;
  padding: 5% 10% 0% 10%;
}

#first img, #semaine img {
  border-radius: 16px;
}

#nav-container {
  background-color: #0f1010!important;
}

h1 {
  font: normal normal 800 38px/47px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
}

h2 {
  text-align: left;
  font: normal normal 800 30px/37px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

p {
  text-align: left;
  font: normal normal 500 22px/30px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  opacity: 1;
}

#first h1 {
  text-align: left;
  color: #ffffff;
  margin-bottom: 32px;
}

#first p {
  text-align: left;
  font: normal normal 500 26px/38px Montserrat;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 50px;
}

#first p span {
  font-weight: 800;
}

#checks {
  padding: 5% 23% 0 23%;
}

#checks .check {
  display: flex;
  margin-bottom: 36px;
  align-items: center;
}

#checks .check p {
  margin-bottom: 0;
}

#checks .title {
  position: relative;
  margin-bottom: 7%;
}

.title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: auto;
  opacity: 1;
}

#checks h1,
#apres h1 {
  color: #0f1010;
}

.check img {
  height: 46px;
  margin-right: 16px;
}

#semaine {
  padding: 22% 8% 17% 8%;
  background-image: url("../assets/Images/Training/Enmascarar grupo 148.png"),
    url("../assets/Images/Training/Grupo 1103.png");
  background-size: 100% 70%, auto;
  background-repeat: no-repeat;
  background-position: 0 0, 50% 100%;
}

.fa-check-circle,
#semaine >>> .fa-check-circle {
  color: #ff0313;
  margin-right: 16px;
}

#semaine p,
#semaine >>> p {
  text-align: left;
  font: normal normal 500 22px/30px Montserrat;
  letter-spacing: 0.44px;
  color: #ffffff !important;
}

#semaine p span,
#semaine >>> p span {
  font-weight: 800;
}

#semaine h3 {
  font: normal normal 600 38px/30px Montserrat;
  letter-spacing: 0px;
  color: #0f1010;
  opacity: 1;
  margin-top: 20%;
  line-height: 1.25;
}

#semaine .col-6:nth-child(2) {
  padding-left: 42px;
}

#apres {
  padding: 0 8.5%;
}

#apres .title {
  position: relative;
  margin-bottom: 5%;
}

/* #apres .title::after {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  bottom: -25%;
  left: 48%;
  opacity: 1;
} */

#apres .col-6:first-child {
  padding-right: 30px;
}

#semaine .col-6:nth-child(2) {
  padding-right: 102px;
}

#apres p,
#apres >>> p {
  text-align: left;
  font: normal normal 500 22px/40px Montserrat;
  letter-spacing: 0.44px;
  color: #242424;
  opacity: 1;
  margin-bottom: 40px;
}

#apres h4 {
  text-align: left;
  font: normal normal bold 28px/40px Montserrat;
  letter-spacing: 0.56px;
  color: #0f1010;
  opacity: 1;
  margin: 50px 0;
}

#programme {
  background-image: url("../assets/Images/Practice/Grupo 1084.png"),
    url("../assets/Images/Practice/Grupo 1083.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 4% 4%, 96% 42%;
}

#paypal {
  padding: 7% 12% 0 12%;
}

#card {
  background-image: url("../assets/Images/Idees/graf.png");
  background-color: #0f1010;
  background-repeat: no-repeat;
  background-position: 0 0, 0 0;
  box-shadow: 0px 3px 30px #00000029;
  border-radius: 40px;
  opacity: 1;
  padding: 5% 0;
}

.text-card {
  padding: 0 10%;
}

#card h1 {
  text-align: center;
  color: #ffffff;
  margin-bottom: 70px;
}

#card p {
  text-align: center;
  font: normal normal 500 22px/40px Montserrat;
  letter-spacing: 0.44px;
  color: #d3d3d3;
  opacity: 1;
}

.price {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}

.price p {
  margin: 0 15px;
  color: #ffffff !important;
  font-weight: 300 !important;
  font-size: 50px !important;
  line-height: 40px !important;
  letter-spacing: 1px !important;
}

.price h2 {
  font: normal normal bold 50px/40px Montserrat;
  letter-spacing: 1px;
  color: #ffdd00;
  opacity: 1;
  letter-spacing: 1px !important;
}

.price h2.offer {
  text-decoration: line-through;
  font-weight: 300 !important;
  font-size: 50px !important;
  color: #ffffff !important;
  line-height: 40px !important;
}

.btn-pp {
  background: #ffdd00 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  padding: 0 15%;
  margin-left: 7%;
  margin-right: 40px;
}

.btn-pp img {
  height: 95px;
}

.btn-tc {
  border: 2px solid #ffffff;
  border-radius: 50px;
  opacity: 1;
  text-align: center;
  font: normal normal 500 22px/40px Montserrat;
  letter-spacing: 0.44px;
  color: #ffffff;
  opacity: 1;
  padding: 0 12.4%;
}

.fa-credit-card {
  margin-right: 15px;
}

.dev {
  margin-top: 40px;
  margin-bottom: 30px;
}

.dev p {
  font-style: italic !important;
  color: #a9a9a9 !important;
}

#satisfaction {
  background-image: url("../assets/Images/Practice/Enmascarar grupo 149.png"),
    url("../assets/Images/Cycles/Enmascarar grupo 149.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 0 0, 50% 100%;
  padding: 14% 0%;
}

#satisfaction #garantie {
  padding-left: 12%;
  padding-right: 6%;
  position: relative;
}

#satisfaction #garantie::after {
  content: "";
  position: absolute;
  height: 75%;
  left: 99%;
  top: 20%;
  border-right: 1px solid rgba(255, 255, 255, 0.7);
}

#satisfaction #paiement {
  padding: 7% 8% 0 5%;
}

#satisfaction h2 {
  text-align: center;
  margin: 27px 0;
}

#satisfaction p {
  text-align: center;
  color: #ffffff !important;
}

#page {
  padding: 8% 10% 0 10%;
}

#page h3 {
  text-align: center;
  font: normal normal 600 35px/50px Montserrat;
  letter-spacing: 0px;
  opacity: 1;
  color: #ffffff;
}

#page h3 a {
  color: #f9334a;
}

#exercices {
  padding: 0 10% 5% 10%;
  background-image: url("../assets/Images/Idees/Grupo 1042.png"),
    url("../assets/Images/Idees/Enmascarar grupo 150.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: 91% 98%, 0 45%;
}

#exercices .title::after {
  bottom: 35px;
}

#exercices p {
  text-align: center;
  font: normal normal 500 24px/29px Montserrat;
  letter-spacing: 0px;
  color: #0f1010 !important;
  opacity: 1;
}

#exercices h1 {
  color: #0f1010;
  margin-top: 2%;
  margin-bottom: 5%;
  text-transform: uppercase;
}

#exercices >>> .card-text {
  position: relative;
  margin-top: 60px;
}

#exercices >>> .card-text::before {
  content: "";
  position: absolute;
  border-bottom: 3px solid #ff0313;
  width: 60px;
  left: 0;
  top: -30px;
  opacity: 1;
}

@media (max-width: 1880px) {
  #semaine h3 {
    margin-top: 20%;
  }
}

@media (max-width: 1780px) {
  #semaine h3 {
    margin-top: 24%;
  }

  #page {
    padding-top: 12%;
  }
}

@media (max-width: 1560px) {
}

@media (max-width: 1440px) {
  #first {
    padding: 5% 5% 0% 5%;
  }

  #checks {
    padding: 5% 15% 0 15%;
  }

  #semaine .col-6:nth-child(2) {
    padding-right: 0;
  }

  #semaine h3 {
    margin-top: 20%;
  }

  #semaine {
    padding: 25% 5% 22% 5%;
  }

  #paypal {
    padding: 7% 10%;
  }

  .btn-pp {
    margin-left: 4%;
    margin-right: 20px;
  }

  .btn-tc {
    padding: 0 10%;
  }

  #satisfaction {
    padding: 17% 0;
  }

  #page {
    padding: 10% 10% 0 10%;
  }
}

@media (max-width: 1360px) {
  #semaine h3 {
    margin-top: 22%;
  }
}

@media (max-width: 1024px) {
  #first {
    background-image: url("../assets/Images/Cycles/Enmascarar grupo 163.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: 0 -40px;
    padding: 0% 5% 15% 5%;
  }

  h1,
  #garantie h2 {
    font-size: 18px;
    line-height: 22px;
  }

  #first h1 {
    text-align: center;
    margin-bottom: 26px;
  }

  #first p {
    text-align: center;
    font: normal normal 300 16px/18px Montserrat !important;
  }

  #first p span {
    font-weight: 300 !important;
  }

  #first .img-fluid {
    margin-bottom: 15px;
  }

  #checks {
    padding: 5% 4% 20% 3%;
  }

  #checks .title {
    margin-bottom: 40px;
  }

  #checks .check {
    margin-bottom: 20px;
  }

  #checks .check img {
    height: 32px;
  }

  #checks .check p {
    font-size: 16px;
    line-height: 20px;
  }

  #semaine {
    padding: 12% 5%;
    background-image: url("../assets/Images/Training/Enmascarar grupo 223.png");
    background-size: 100% 90%;
    background-repeat: no-repeat;
    background-position: 0 0;
  }

  #semaine >>> p {
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.1px;
    text-align: left;
  }

  #semaine img {
    margin-bottom: 43px;
  }

  #semaine h3 {
    font-size: 15px;
    line-height: 16px;
    margin-top: 10%;
  }

  #apres {
    padding: 0 2%;
  }

  #apres .title {
    padding: 0px 20px;
    margin-bottom: 60px;
  }

  #apres .title::after {
    bottom: -30px;
  }

  #apres .col-sm-12 {
    padding-left: 20px;
  }

  #apres >>> p {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.24px;
    margin-bottom: 20px;
  }

  #apres h4 {
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0;
  }

  #programme,
  #paypal #card {
    background-image: none;
  }

  #paypal {
    padding: 7% 5%;
  }

  #paypal h1 {
    font-size: 20px;
    line-height: 22px;
    margin-bottom: 23px;
  }

  #card {
    padding: 12% 0;
  }

  #card p,
  #satisfaction p {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.12px;
    margin-bottom: 5px;
  }

  #card .text-card {
    padding: 0;
  }

  #card .price h2,
  #card .price h2.offer,
  #card .price p {
    font-size: 24px !important;
  }

  #card .price p {
    letter-spacing: 0.48px !important;
  }

  #card .btn-pp {
    padding: 0 38%;
    margin-left: 18px;
    margin-right: 22px;
    margin-bottom: 20px;
    width: 100%;
  }

  #card .btn-pp img {
    height: auto;
  }

  #card .btn-tc {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.24px;
    margin-left: 18px;
    margin-right: 22px;
    padding: 15px 30%;
    width: 100%;
  }

  #card .buttons {
    justify-content: center;
  }

  #card .dev p {
    line-height: 40px;
    letter-spacing: 0.24px;
  }

  #card .dev p img {
    height: 40px;
  }

  #card .end {
    padding: 0 5% !important;
  }

  #card .end p {
    font-size: 14px !important;
    line-height: 20px;
    letter-spacing: 0.2px;
  }

  #satisfaction {
    background-image: url("../assets/Images/Cycles/Satisfaction Garantie.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    padding: 10% 0 2%;
  }

  #satisfaction #garantie {
    padding: 0 6%;
    margin-bottom: 50px;
  }

  #satisfaction #garantie::after {
    border-right: 0;
  }

  #paiement img {
    max-width: 50%;
  }

  #paiement h2 {
    font-size: 18px;
    line-height: 26px;
  }

  #page {
    padding: 15% 9% 4%;
  }

  #page h3 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.12px;
  }

  #page h3 br {
    display: none;
  }

  #exercices {
    background-image: none;
    padding: 5% 7% 10% 7%;
  }

  #exercices .title {
    padding: 0;
    position: relative;
  }

  #exercices .title::after {
    bottom: 25%;
  }

  #exercices h1 {
    text-transform: initial;
    margin-bottom: 40px;
  }

  #exercices p {
    text-align: center;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.12px;
    color: #151515 !important;
  }

  #exercices >>> .card-title {
    font-size: 15px;
    line-height: 16px;
  }

  #exercices >>> .card-text p {
    font-size: 14px;
  }

  #exercices >>> .row {
    justify-content: center;
  }
}

@media (max-width: 825px) {
  #semaine {
    padding: 22% 5% 40% 5%;
  }

  #semaine h3 {
    margin-top: 35%;
  }

  #satisfaction {
    padding: 17% 0;
  }

    #page {
    padding: 38% 9% 4%;
  }
}

@media (max-width: 425px) {
  #first {
    background-size: 100%;
  }

  #semaine {
    background-size: 100% 96%;
  }

  #card .btn-pp {
    padding: 0 33%;
    margin-left: 18px;
    margin-right: 22px;
    margin-bottom: 20px;
  }

  #card .btn-tc {
    padding: 15px 20%;
  }

  #satisfaction {
    padding: 30% 0% 20%;
  }
}

@media (max-width: 375px) {
  #card .btn-tc {
    padding: 15px 17%;
  }

  #page {
    padding: 38% 9% 2%;
  }
}
</style>
